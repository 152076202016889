/* eslint-disable @typescript-eslint/no-non-null-assertion */
import toast from 'react-hot-toast';
import { useId, Fragment } from 'react';

import {
	Button,
	useAxios,
	FormErrors,
	ContentSection,
	DescriptionList,
	DescriptionItem,
	ContentSectionDivider,
} from '@pangea-lis-apps/ui';
import {
	FORMS,
	ASSAYS,
	FieldProps,
	capitalize,
	formatDate,
	BLADDERCARE,
	ICD_10_CODES,
	requiredStyles,
	REQUISITION_FORM,
	getLabelFromValue,
	MiscAndAttachmentsFieldProps,
	generateStringFromArrayOptionValues,
} from '@pangea-lis-apps/utils';

const BLADDERCARE_V4_1 = BLADDERCARE['4.1'];

const ProviderInformation = ({ data, headingAction }: FieldProps) => {
	if (data.type === 'clinic')
		return (
			<ContentSection
				heading="Provider Info"
				headingAction={headingAction}
			>
				<DescriptionList>
					<DescriptionItem
						term="First Name"
						termStyles={requiredStyles(
							data.requisition_form.provider_first_name
						)}
						details={data.requisition_form.provider_first_name}
					/>
					<DescriptionItem
						term="Last Name"
						termStyles={requiredStyles(
							data.requisition_form.provider_last_name
						)}
						details={data.requisition_form.provider_last_name}
					/>
					<DescriptionItem
						term="National Provider Identifier (NPI)"
						termStyles={requiredStyles(
							data.requisition_form
								.provider_national_provider_identifier
						)}
						details={
							data.requisition_form
								.provider_national_provider_identifier
						}
					/>
				</DescriptionList>
			</ContentSection>
		);

	return (
		<ContentSection heading="Provider Info" headingAction={headingAction}>
			<DescriptionList>
				<DescriptionItem
					term="Name"
					details={data.customer.first_name.concat(
						' ',
						data.customer.last_name
					)}
				/>
			</DescriptionList>
		</ContentSection>
	);
};

const OrganizationInformation = ({ data, headingAction }: FieldProps) => {
	if (data.type === 'clinic')
		return (
			<ContentSection
				heading="Organization Info"
				headingAction={headingAction}
			>
				<DescriptionList>
					<DescriptionItem
						term="Name"
						details={data.requisition_form.organization_name}
						termStyles={requiredStyles(
							data.requisition_form.organization_name
						)}
					/>
					<DescriptionItem
						term="National Provider Identifier (NPI)"
						termStyles={requiredStyles(
							data.requisition_form
								.organization_national_provider_identifier!
						)}
						details={
							data.requisition_form
								.organization_national_provider_identifier
						}
					/>
					<DescriptionItem
						term="Phone Number"
						termStyles={requiredStyles(
							data.requisition_form.organization_phone_number
						)}
						details={
							data.requisition_form.organization_phone_number
						}
					/>
					<DescriptionItem
						termStyles={requiredStyles(
							data.requisition_form.organization_address_address
						)}
						term="Address"
						details={
							data.requisition_form.organization_address_address
						}
					/>
					<DescriptionItem
						term="Address Line 2"
						details={
							data.requisition_form.organization_address_address2
						}
					/>
					<div className="sm:col-span-1"></div>
					<DescriptionItem
						term="City"
						termStyles={requiredStyles(
							data.requisition_form.organization_address_city
						)}
						details={
							data.requisition_form.organization_address_city
						}
					/>
					<DescriptionItem
						term="State"
						termStyles={requiredStyles(
							data.requisition_form.organization_address_state
						)}
						details={getLabelFromValue(
							data.requisition_form.organization_address_state,
							FORMS.states
						)}
					/>
					<div className="sm:col-span-1"></div>
					<DescriptionItem
						term="ZIP Code"
						termStyles={requiredStyles(
							data.requisition_form.organization_address_zip_code
						)}
						details={
							data.requisition_form.organization_address_zip_code
						}
					/>
					<DescriptionItem
						term="Country"
						termStyles={requiredStyles(
							data.requisition_form.organization_address_country
						)}
						details={getLabelFromValue(
							data.requisition_form.organization_address_country,
							FORMS.countries
						)}
					/>
					<div className="sm:col-span-1"></div>
				</DescriptionList>
			</ContentSection>
		);

	return (
		<ContentSection
			heading="Organization Info"
			headingAction={headingAction}
		>
			<DescriptionList>
				<DescriptionItem term="Name" details={data.organization.name} />
				<DescriptionItem
					term="Phone Number"
					details={data.organization.phone_number}
				/>
				<DescriptionItem term="Address">
					<p>
						{data.organization.address}
						<br />
						{data.organization.city}, {data.organization.state}{' '}
						{data.organization.zip_code} {data.organization.country}
					</p>
				</DescriptionItem>
			</DescriptionList>
		</ContentSection>
	);
};

const OrderAndSpecimenInformation = ({
	data,
	hide,
	headingAction,
}: FieldProps) => {
	if (data.type === 'clinic')
		return (
			<ContentSection
				heading="Order and specimen information"
				headingAction={headingAction}
			>
				<DescriptionList>
					<DescriptionItem
						term="Assay"
						details={getLabelFromValue(
							data.requisition_form.sample_assay,
							ASSAYS['assays']
						)}
					/>
					{!(hide && hide.includes('ICD-10 Code(s)')) && (
						<Fragment>
							<div className="sm:col-span-2"></div>
							<DescriptionItem term="ICD-10 Code(s)">
								{data.requisition_form.order_icd_10_codes
									.length ? (
									<ul className="list-disc list-inside">
										{data.requisition_form.order_icd_10_codes.map(
											(code: string, index: number) => (
												<li key={index}>
													{getLabelFromValue(
														code,
														ICD_10_CODES.codes
															.bladdercare
													)}
												</li>
											)
										)}
									</ul>
								) : null}
							</DescriptionItem>
						</Fragment>
					)}
					{!(
						hide && hide.includes('Provider Signature Provided')
					) && (
						<Fragment>
							<div className="sm:col-span-2"></div>
							<DescriptionItem
								term="Provider Signature Provided"
								termStyles={requiredStyles(
									data.requisition_form
										.order_ordering_provider_signature_provided
								)}
								details={
									data.requisition_form
										.order_ordering_provider_signature_provided
								}
							/>
						</Fragment>
					)}
					{!(hide && hide.includes('Date Provided')) && (
						<DescriptionItem
							term="Date Provided"
							termStyles={requiredStyles(
								data.requisition_form
									.order_ordering_provider_date
							)}
							details={formatDate(
								data.requisition_form
									.order_ordering_provider_date
							)}
						/>
					)}
					{!(hide && hide.includes('Collection Date')) && (
						<DescriptionItem
							term="Collection Date"
							termStyles={requiredStyles(
								data.requisition_form.sample_collection_date
							)}
							details={formatDate(
								data.requisition_form.sample_collection_date
							)}
						/>
					)}
				</DescriptionList>
			</ContentSection>
		);

	return (
		<ContentSection
			heading="Order information"
			headingAction={headingAction}
		>
			<DescriptionList>
				<DescriptionItem
					term="Assay"
					details={getLabelFromValue(
						data.sample.assay,
						ASSAYS['assays']
					)}
				/>
			</DescriptionList>
		</ContentSection>
	);
};

const PatientInformation = ({ data, headingAction }: FieldProps) => {
	if (data.type === 'clinic')
		return (
			<ContentSection
				headingAction={headingAction}
				heading="Provided patient information"
			>
				<DescriptionList>
					<DescriptionItem
						term="First Name"
						termStyles={requiredStyles(
							data.requisition_form.patient_first_name
						)}
						details={data.requisition_form.patient_first_name}
					/>
					<DescriptionItem
						term="Middle Name"
						details={data.requisition_form.patient_middle_name}
					/>
					<DescriptionItem
						term="Last Name"
						termStyles={requiredStyles(
							data.requisition_form.patient_last_name
						)}
						details={data.requisition_form.patient_last_name}
					/>
					<DescriptionItem
						term="Medical Record Number (MRN)"
						details={
							data.requisition_form.patient_medical_record_number
						}
					/>
					<DescriptionItem
						term="Date of Birth"
						termStyles={requiredStyles(
							data.requisition_form.patient_date_of_birth
						)}
						details={formatDate(
							data.requisition_form.patient_date_of_birth
						)}
					/>
					<div className="sm:col-span-1"></div>
					<DescriptionItem
						term="Sex"
						termStyles={requiredStyles(
							data.requisition_form.patient_sex
						)}
						details={
							data.requisition_form.patient_sex &&
							capitalize(data.requisition_form.patient_sex)
						}
					/>
					<DescriptionItem
						term="Ethnicity"
						details={getLabelFromValue(
							data.requisition_form.patient_ethnicity,
							BLADDERCARE_V4_1.ethnicity_options
						)}
					/>
					<div className="sm:col-span-1"></div>
					{/* <DescriptionItem
						term="Race"
						details={
							data.requisition_form.patient_race.length
								? data.requisition_form.patient_race
										.map((race) =>
											getLabelFromValue(
												race,
												BLADDERCARE_V4_1.race_options
											)
										)
										.join(', ')
								: null
						}
					/> */}
					<DescriptionItem
						termStyles={requiredStyles(
							data.requisition_form.patient_address
						)}
						term="Address"
						details={data.requisition_form.patient_address}
					/>
					<DescriptionItem
						term="Address Line 2"
						details={data.requisition_form.patient_address2}
					/>
					<div className="sm:col-span-1"></div>
					<DescriptionItem
						term="City"
						termStyles={requiredStyles(
							data.requisition_form.patient_city
						)}
						details={data.requisition_form.patient_city}
					/>
					<DescriptionItem
						term="State"
						termStyles={requiredStyles(
							data.requisition_form.patient_state
						)}
						details={getLabelFromValue(
							data.requisition_form.patient_state,
							FORMS.states
						)}
					/>
					<div className="sm:col-span-1"></div>
					<DescriptionItem
						term="ZIP Code"
						termStyles={requiredStyles(
							data.requisition_form.patient_zip_code
						)}
						details={data.requisition_form.patient_zip_code}
					/>
					<DescriptionItem
						term="Country"
						termStyles={requiredStyles(
							data.requisition_form.patient_country
						)}
						details={getLabelFromValue(
							data.requisition_form.patient_country,
							FORMS.countries
						)}
					/>
					<div className="sm:col-span-1"></div>
					<DescriptionItem
						term="Phone Number"
						details={data.requisition_form.patient_phone_number}
					/>
					<DescriptionItem
						term="Email Address"
						details={data.requisition_form.patient_email_address}
					/>
				</DescriptionList>
			</ContentSection>
		);

	return null;
};

const BillingInformation = ({ data, headingAction }: FieldProps) => {
	if (data.type === 'clinic')
		return (
			<Fragment>
				<ContentSection heading="Billing status">
					<DescriptionList>
						<DescriptionItem
							term="Follow Up Required?"
							details={
								data.requisition_form.metadata
									.billing_verification.follow_up.required
							}
						/>
						{data.requisition_form.metadata.billing_verification
							.follow_up.required && (
							<Fragment>
								<DescriptionItem
									term="Follow up with?"
									details={capitalize(
										data.requisition_form.metadata
											.billing_verification.follow_up.with
									)}
								/>
								<div className="sm:col-span-1"></div>
								<DescriptionItem term="Follow up again in?">
									{'Within '}
									{
										data.requisition_form.metadata
											.billing_verification.follow_up
											.in_days
									}
									{' Days'}
								</DescriptionItem>
								<DescriptionItem term="Days since last follow up?">
									{Math.round(
										(new Date().getTime() -
											new Date(
												data.requisition_form.metadata.billing_verification.follow_up.metadata.date?.$date
											).getTime()) /
											(1000 * 3600 * 24)
									)}
									{' Days'}
								</DescriptionItem>
							</Fragment>
						)}
					</DescriptionList>
				</ContentSection>

				<ContentSectionDivider />

				<ContentSection
					heading="Billing information"
					headingAction={headingAction}
				>
					<DescriptionList>
						<DescriptionItem
							term="Billing Method"
							termStyles={requiredStyles(
								data.requisition_form.billing_method
							)}
							details={getLabelFromValue(
								data.requisition_form.billing_method,
								BLADDERCARE_V4_1.billing_information_payer_options
							)}
						/>
						<DescriptionItem
							term="Billing Verified?"
							termStyles={requiredStyles(
								data.requisition_form.billing_verified
							)}
							details={data.requisition_form.billing_verified}
						/>
					</DescriptionList>
				</ContentSection>

				{data.requisition_form.billing_method ===
					'patient_self_pay' && (
					<ContentSection heading="Payment verification">
						<DescriptionList>
							<DescriptionItem
								term="Patient Payment Received?"
								termStyles={requiredStyles(
									data.requisition_form
										.billing_patient_payment_received
								)}
								details={
									data.requisition_form
										.billing_patient_payment_received
								}
							/>
							<DescriptionItem
								term="Patient Payment Payee"
								termStyles={requiredStyles(
									data.requisition_form
										.billing_patient_payment_payee
								)}
								details={getLabelFromValue(
									data.requisition_form
										.billing_patient_payment_payee,
									REQUISITION_FORM['shared']
										.patient_payment_payee_options
								)}
							/>
						</DescriptionList>
					</ContentSection>
				)}

				{data.requisition_form.billing_method === 'client' && (
					<Fragment>
						<ContentSection heading="Client information">
							<DescriptionList>
								<DescriptionItem
									term="Name"
									termStyles={requiredStyles(
										data.requisition_form
											.billing_client_client_name
									)}
									details={
										data.requisition_form
											.billing_client_client_name
									}
								/>
								<DescriptionItem
									term="Institution"
									termStyles={requiredStyles(
										data.requisition_form
											.billing_client_client_institution
									)}
									details={
										data.requisition_form
											.billing_client_client_institution
									}
								/>
							</DescriptionList>
						</ContentSection>
						<ContentSection heading="Client's billing contact information">
							<DescriptionList>
								<DescriptionItem
									term="Name"
									termStyles={requiredStyles(
										data.requisition_form
											.billing_client_billing_contact_name
									)}
									details={
										data.requisition_form
											.billing_client_billing_contact_name
									}
								/>
								<DescriptionItem
									term="Institution"
									termStyles={requiredStyles(
										data.requisition_form
											.billing_client_billing_contact_institution
									)}
									details={
										data.requisition_form
											.billing_client_billing_contact_institution
									}
								/>
								<div className="sm:col-span-1"></div>
								<DescriptionItem
									term="Address"
									termStyles={requiredStyles(
										data.requisition_form
											.billing_client_billing_contact_address
									)}
									details={
										data.requisition_form
											.billing_client_billing_contact_address
									}
								/>
								<div className="sm:col-span-2"></div>
								<DescriptionItem
									term="City"
									termStyles={requiredStyles(
										data.requisition_form
											.billing_client_billing_contact_city
									)}
									details={
										data.requisition_form
											.billing_client_billing_contact_city
									}
								/>
								<DescriptionItem
									term="State"
									termStyles={requiredStyles(
										data.requisition_form
											.billing_client_billing_contact_state
									)}
									details={getLabelFromValue(
										data.requisition_form
											.billing_client_billing_contact_state,
										FORMS.states
									)}
								/>
								<div className="sm:col-span-1"></div>
								<DescriptionItem
									term="ZIP Code"
									termStyles={requiredStyles(
										data.requisition_form
											.billing_client_billing_contact_zip_code
									)}
									details={
										data.requisition_form
											.billing_client_billing_contact_zip_code
									}
								/>
								<DescriptionItem
									term="Country"
									termStyles={requiredStyles(
										data.requisition_form
											.billing_client_billing_contact_country
									)}
									details={getLabelFromValue(
										data.requisition_form
											.billing_client_billing_contact_country,
										FORMS.countries
									)}
								/>
								<div className="sm:col-span-1"></div>
								<DescriptionItem
									term="Phone Number"
									termStyles={requiredStyles(
										data.requisition_form
											.billing_client_billing_contact_phone_number
									)}
									details={
										data.requisition_form
											.billing_client_billing_contact_phone_number
									}
								/>
								<DescriptionItem
									term="Fax Number"
									details={
										data.requisition_form
											.billing_client_billing_contact_fax_number
									}
								/>
								<DescriptionItem
									term="Email Address"
									details={
										data.requisition_form
											.billing_client_billing_contact_email_address
									}
								/>
							</DescriptionList>
						</ContentSection>
					</Fragment>
				)}

				{data.requisition_form.billing_method === 'insurance' && (
					<Fragment>
						<ContentSection heading="Insurance information">
							<DescriptionList>
								<DescriptionItem
									term="Name"
									termStyles={requiredStyles(
										data.requisition_form
											.billing_insurance_name
									)}
									details={
										data.requisition_form
											.billing_insurance_name
									}
								/>
								<div className="sm:col-span-2"></div>
								<DescriptionItem
									term="Address"
									details={
										data.requisition_form
											.billing_insurance_address
									}
								/>
								<div className="sm:col-span-2"></div>
								<DescriptionItem
									term="City"
									details={
										data.requisition_form
											.billing_insurance_city
									}
								/>
								<DescriptionItem
									term="State"
									details={getLabelFromValue(
										data.requisition_form
											.billing_insurance_state,
										FORMS.states
									)}
								/>
								<div className="sm:col-span-1"></div>
								<DescriptionItem
									term="ZIP Code"
									details={
										data.requisition_form
											.billing_insurance_zip_code
									}
								/>
								<DescriptionItem
									term="Country"
									details={getLabelFromValue(
										data.requisition_form
											.billing_insurance_country,
										FORMS.countries
									)}
								/>
								<div className="sm:col-span-1"></div>
								<DescriptionItem
									term="Phone Number"
									termStyles={requiredStyles(
										data.requisition_form
											.billing_insurance_phone_number
									)}
									details={
										data.requisition_form
											.billing_insurance_phone_number
									}
								/>
								<DescriptionItem
									term="Fax Number"
									details={
										data.requisition_form
											.billing_insurance_fax_number
									}
								/>
								<DescriptionItem
									term="Email Address"
									details={
										data.requisition_form
											.billing_insurance_email_address
									}
								/>
								<DescriptionItem
									term="Subscriber first name"
									details={
										data.requisition_form
											.billing_insurance_subscriber_first_name
									}
								/>
								<DescriptionItem
									term="Subscriber last name"
									details={
										data.requisition_form
											.billing_insurance_subscriber_last_name
									}
								/>
								<div className="sm:col-span-1"></div>
								<DescriptionItem
									term="Subscriber policy number"
									termStyles={requiredStyles(
										data.requisition_form
											.billing_insurance_subscriber_policy_number
									)}
									details={
										data.requisition_form
											.billing_insurance_subscriber_policy_number
									}
								/>
								<DescriptionItem
									term="Subscriber group number"
									details={
										data.requisition_form
											.billing_insurance_subscriber_group_number
									}
								/>
							</DescriptionList>
						</ContentSection>
					</Fragment>
				)}
			</Fragment>
		);

	return null;
};

const RelevantClinicalInformation = ({ data, headingAction }: FieldProps) => {
	if (data.type === 'clinic')
		return (
			<ContentSection
				headingAction={headingAction}
				heading="Relevant clinical information"
			>
				<DescriptionList>
					<DescriptionItem
						term="Date of Diagnosis"
						details={formatDate(
							data.requisition_form
								.relevant_clinical_information_date_of_diagnosis
						)}
					/>
					<div className="sm:col-span-2"></div>
					<DescriptionItem
						term="Urological Cancers"
						details={data.requisition_form.relevant_clinical_information_urological_cancers
							.map((option: string) =>
								getLabelFromValue(
									option,
									BLADDERCARE_V4_1.urological_cancers_options
								)
							)
							.join(', ')}
					/>
					<DescriptionItem
						term="Urological Conditions"
						details={data.requisition_form.relevant_clinical_information_urological_conditions
							.map((option: string) =>
								getLabelFromValue(
									option,
									BLADDERCARE_V4_1.urological_conditions_options
								)
							)
							.join(', ')}
					/>
					<DescriptionItem
						term="Treatments"
						details={data.requisition_form.relevant_clinical_information_treatments
							.map((option: string) =>
								getLabelFromValue(
									option,
									BLADDERCARE_V4_1.treatment_options
								)
							)
							.join(', ')}
					/>
				</DescriptionList>
			</ContentSection>
		);

	return null;
};

const MiscAndAttachments = ({
	data,
	entity,
	headingAction,
}: MiscAndAttachmentsFieldProps) => {
	const toastId = useId();
	const axios = useAxios(toastId);
	const toastOptions = { id: toastId };

	const isAssociatePortal = process.env['NX_PORTAL_TYPE'] === 'ASSOCIATE';

	const handleDownloadAttachment = async (aws_object_key: string) => {
		if (!axios) return;

		try {
			toast.loading('Downloading...', toastOptions);

			const {
				data: { data: file },
			} = await (
				await axios
			).get(
				`/api/${entity}/data/${
					data._id.$oid
				}/requisition form/attachment/?aws_object_key=${encodeURIComponent(
					aws_object_key
				)}`
			);

			toast.dismiss();

			window.open(file, '_blank');
		} catch (error) {
			console.log(error);
		}
	};

	if (data.type === 'clinic')
		return (
			<ContentSection
				heading="Miscellaneous and Attachments"
				description="Patient signature and date must be provided for the requisition form to be considered complete."
				headingAction={headingAction}
			>
				<DescriptionList>
					<DescriptionItem
						term="Collection Kit Shipping Option"
						details={getLabelFromValue(
							data.requisition_form
								.collection_kit_shipping_option,
							BLADDERCARE_V4_1.collection_kit_shipping_options
						)}
					/>
					<div className="sm:col-span-2"></div>
					<DescriptionItem
						term="Physician Report Delivery Method(s)"
						termStyles={requiredStyles(
							data.requisition_form
								.physician_report_delivery_methods.length > 0
						)}
						details={generateStringFromArrayOptionValues(
							data.requisition_form
								.physician_report_delivery_methods,
							BLADDERCARE_V4_1.physician_report_delivery_method_options
						)}
					/>
					<div className="sm:col-span-2"></div>
					{data.requisition_form.physician_report_delivery_methods.map(
						(option: string) => {
							if (option === 'fax')
								return (
									<DescriptionItem
										key="fax"
										term="Fax Number"
										termStyles={requiredStyles(
											data.requisition_form.physician_report_delivery_methods.includes(
												'fax'
											)
										)}
										details={
											data.requisition_form
												.physician_report_delivery_contact_fax
										}
									/>
								);
							else if (option === 'mail')
								return (
									<DescriptionItem
										key="mail"
										term="Mailing Address"
										termStyles={requiredStyles(
											data.requisition_form.physician_report_delivery_methods.includes(
												'mail'
											)
										)}
										details={
											data.requisition_form
												.physician_report_delivery_contact_mail
										}
									/>
								);
							else if (option === 'email')
								return (
									<DescriptionItem
										key="email"
										term="Email Address"
										termStyles={requiredStyles(
											data.requisition_form.physician_report_delivery_methods.includes(
												'email'
											)
										)}
										details={
											data.requisition_form
												.physician_report_delivery_contact_email
										}
									/>
								);
							else return null;
						}
					)}
					{isAssociatePortal && (
						<DescriptionItem
							key="portal"
							term="Portal"
							termStyles={requiredStyles(
								data.customer.account_activated
							)}
							details={
								data.customer.account_activated
									? 'Account has been activated, and the provider has access to the portal.'
									: "Account is not yet activated. Contact the account manager to set up the provider's account."
							}
						/>
					)}
					<DescriptionItem
						term="Attachments"
						customColSpan="sm:col-span-3"
						termStyles={requiredStyles(
							data.requisition_form.attachments
						)}
					>
						{data.requisition_form.attachments.length ? (
							<div className="space-y-2">
								{data.requisition_form.attachments.map(
									(
										attachment: {
											aws_object_key: string;
											file_name: string;
										},
										index: number
									) => {
										return (
											<div
												key={index}
												className="flex items-center space-x-4"
											>
												<Button
													type="button"
													tier="tertiary"
													text="Download"
													Icon="ArrowDownTrayIcon"
													onClick={() =>
														handleDownloadAttachment(
															attachment.aws_object_key
														)
													}
												/>
												<p className="text-sm">
													{attachment.file_name}
												</p>
											</div>
										);
									}
								)}
							</div>
						) : null}
					</DescriptionItem>
				</DescriptionList>
			</ContentSection>
		);

	return null;
};

export const Fallback = {
	BillingInformation,
	PatientInformation,
	ProviderInformation,
	OrganizationInformation,
	OrderAndSpecimenInformation,
	RelevantClinicalInformation,
	MiscAndAttachments,
};

export default Fallback;
